<template>
  <div class="container">

    <section>
      <div class="text">
        <h1>Happy 2nd Anniversary</h1>
        <p>CowCow & PigPig</p>
      </div>
      <canvas id="birthday"></canvas>
    </section>

    
    <section class="page1">
      <div class="title">
        <h1>皮革收藏图鉴！</h1>
        <p>恭喜牛牛达成「大收藏家」称号</p>
      </div>
      <ul class="gallery">
        <li>
          <img src="./images/1.jpg" alt="">
        </li>
        <li>
          <img src="./images/2.jpg" alt="">
        </li>
        <li>
          <img src="./images/3.jpg" alt="">
        </li>
        <li>
          <img src="./images/4.jpeg" alt="">
        </li>
        <li>
          <img src="./images/5.jpg" alt="">
        </li>
        <li>
          <img src="./images/6.jpg" alt="">
        </li>
        <li>
          <img src="./images/7.jpg" alt="">
        </li>
        <li>
          <img src="./images/8.jpg" alt="">
        </li>
        <li>
          <img src="./images/9.jpeg" alt="">
        </li>
      </ul>
      <div class="animation">
        <img src="./taxi-bull-with-flower.gif" alt="">
      </div>
    </section>





    <section class="page2">
      <div class="content">
        <div class="text">
          <h1 class="title">啦啦啦啦～啦啦～啦啦啦～</h1>
          <p>香牛 & 臭猪</p>
        </div>
        <h1 class="logo">
          <span>🐮</span>
          <img src="https://em-content.zobj.net/source/microsoft-teams/337/blue-heart_1f499.png" alt="">
          <span>🐷</span>
        </h1>
        <div class="time">
          <span class="value">{{ days }}</span><span>天</span>
          <span class="value">{{ hours }}</span><span>时</span>
          <span class="value">{{ minutes }}</span><span>分</span>
          <span class="value">{{ seconds }}</span><span>秒</span>
        </div>
      </div>
    </section>





  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';

const startDayDateObj = new Date('2021-05-05 00:00:00');
const currentTimeObj = ref(new Date());

const days = computed(() => {
  const nowDateObj = currentTimeObj.value;

  const diff = nowDateObj.getTime() - startDayDateObj.getTime();
  return Math.floor(diff / (24 * 3600 * 1000));
});

const hours = computed(() => {
  const nowDateObj = currentTimeObj.value;

  const diff = nowDateObj.getTime() - startDayDateObj.getTime();
  return Math.floor(diff / (3600 * 1000) % 24);
});

const minutes = computed(() => {
  const nowDateObj = currentTimeObj.value;
  const diff = nowDateObj.getTime() - startDayDateObj.getTime();
  return Math.floor(diff / (60 * 1000) % 60);
});

const seconds = computed(() => {
  const nowDateObj = currentTimeObj.value;

  const diff = nowDateObj.getTime() - startDayDateObj.getTime();
  return Math.floor(diff / 1000 % 60);
});

let timer = setInterval(() => {
  currentTimeObj.value = new Date();
}, 1000);

onMounted(() => {
  init();
});

onUnmounted(() => {
  clearInterval(timer);
});

function init() {
  // helper functions
  const PI2 = Math.PI * 2
  const random = (min, max) => Math.random() * (max - min + 1) + min | 0
  const timestamp = () => new Date().getTime()

  // container
  class Birthday {
    constructor() {
      this.resize()

      ctx.fillStyle = `rgb(254,206,47)`
      ctx.fillRect(0, 0, this.width, this.height)

      // create a lovely place to store the firework
      this.fireworks = []
      this.counter = 0

    }

    resize() {
      this.width = canvas.width = window.innerWidth
      let center = this.width / 2 | 0
      this.spawnA = center - center / 4 | 0
      this.spawnB = center + center / 4 | 0

      this.height = canvas.height = window.innerHeight
      this.spawnC = this.height * .1
      this.spawnD = this.height * .5

    }

    onClick(evt) {
      let x = evt.clientX || evt.touches && evt.touches[0].pageX
      let y = evt.clientY || evt.touches && evt.touches[0].pageY

      let count = random(3, 5)
      for (let i = 0; i < count; i++) this.fireworks.push(new Firework(
        random(this.spawnA, this.spawnB),
        this.height,
        x,
        y,
        random(0, 260),
        random(30, 110)))

      this.counter = -1

    }

    update(delta) {
      ctx.globalCompositeOperation = 'hard-light'
      ctx.fillStyle = `rgba(20,20,20,${7 * delta})`
      // ctx.fillStyle = `rgb(254,206,47)`
      ctx.fillRect(0, 0, this.width, this.height)

      ctx.globalCompositeOperation = 'lighter'
      for (let firework of this.fireworks) firework.update(delta)

      // if enough time passed... create new new firework
      this.counter += delta * 3 // each second
      if (this.counter >= 1) {
        this.fireworks.push(new Firework(
          random(this.spawnA, this.spawnB),
          this.height,
          random(0, this.width),
          random(this.spawnC, this.spawnD),
          random(0, 360),
          random(30, 110)))
        this.counter = 0
      }

      // remove the dead fireworks
      if (this.fireworks.length > 1000) this.fireworks = this.fireworks.filter(firework => !firework.dead)

    }
  }

  class Firework {
    constructor(x, y, targetX, targetY, shade, offsprings) {
      this.dead = false
      this.offsprings = offsprings

      this.x = x
      this.y = y
      this.targetX = targetX
      this.targetY = targetY

      this.shade = shade
      this.history = []
    }
    update(delta) {
      if (this.dead) return

      let xDiff = this.targetX - this.x
      let yDiff = this.targetY - this.y
      if (Math.abs(xDiff) > 3 || Math.abs(yDiff) > 3) { // is still moving
        this.x += xDiff * 2 * delta
        this.y += yDiff * 2 * delta

        this.history.push({
          x: this.x,
          y: this.y
        })

        if (this.history.length > 20) this.history.shift()

      } else {
        if (this.offsprings && !this.madeChilds) {

          let babies = this.offsprings / 2
          for (let i = 0; i < babies; i++) {
            let targetX = this.x + this.offsprings * Math.cos(PI2 * i / babies) | 0
            let targetY = this.y + this.offsprings * Math.sin(PI2 * i / babies) | 0

            birthday.fireworks.push(new Firework(this.x, this.y, targetX, targetY, this.shade, 0))

          }

        }
        this.madeChilds = true
        this.history.shift()
      }

      if (this.history.length === 0) this.dead = true
      else if (this.offsprings) {
        for (let i = 0; this.history.length > i; i++) {
          let point = this.history[i]
          ctx.beginPath()
          ctx.fillStyle = 'hsl(' + this.shade + ',100%,' + i + '%)'
          ctx.arc(point.x, point.y, 1, 0, PI2, false)
          ctx.fill()
        }
      } else {
        ctx.beginPath()
        ctx.fillStyle = 'hsl(' + this.shade + ',100%,50%)'
        ctx.arc(this.x, this.y, 1, 0, PI2, false)
        ctx.fill()
      }

    }
  }

  let canvas = document.getElementById('birthday')
  let ctx = canvas.getContext('2d')



  let then = timestamp()

  let birthday = new Birthday();
  window.onresize = () => birthday.resize()
  document.onclick = evt => birthday.onClick(evt)
  document.ontouchstart = evt => birthday.onClick(evt);

  (function loop() {
    requestAnimationFrame(loop)
    let now = timestamp()
    let delta = now - then

    then = now
    birthday.update(delta / 1000)

  })()
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* background: rgb(254,206,47); */
  cursor: crosshair;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

section {
  position: relative;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text {
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 48px;
}

.text h1 {
  font-size: 36px;
  font-weight: bolder;
}

.text p {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 400;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}


.page2 {
  display: flex;
  padding: 48px;
  background-image: url('./bg.svg');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.time {
  margin-top: 96px;
  text-align: center;
}

.content .logo {
  font-size: 36px;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 64px;

}

.content .logo img {
  width: 128px;
}

.content .logo span {
  font-size: 48px;
}

.content span {
  font-size: 18px;
}

.content span.value {
  color: #fff;
  font-weight: bolder;
  padding: 2px 6px;
  background-color: #666;
  border-radius: 8px;
  margin-right: 4px;
}

.content span.value:not(:first-child) {
  margin-left: 4px;
}


.animation {
  margin-top: 48px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation img {
  width: 200px;
}

.page2 .text {
  color: #222;
  position: relative;
  z-index: 1;
  padding: 0;
}

.page2 .text .title {
  font-size: 36px;
  font-weight: bolder;
}

.page2 .text p {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 400;
}

.page1 .title {
  color: #222;
  position: relative;
  z-index: 1;
  padding: 0;
}

.page1 .title h1 {
  font-size: 36px;
  font-weight: bolder;
}

.page1 .title p {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 400;
}

.page1 .gallery {
  margin-top: 48px;
  list-style: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;
}

.page1 .gallery li img {
  width:100%;
  aspect-ratio: 1;
}

.page1 {
  padding: 48px;
}


</style>
